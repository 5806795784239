import domReady from '@wordpress/dom-ready';

domReady(() => {
  const advantagesBlock = document.querySelector('.advantage-block');
  const advantagesSlider = document.querySelector('.advantage-block__slider');

  if ('IntersectionObserver' in window && window.innerWidth >= 992) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const scrollPourcentage =
            advantagesBlock.getBoundingClientRect().bottom / 20 - 70;
          advantagesSlider.style.transform =
            'translateY(' + scrollPourcentage + '%)';
          // Arrêter d'observer une fois que la transformation est appliquée
          observer.unobserve(advantagesBlock);
        }
      });
    });

    // Commencer à observer la section
    observer.observe(advantagesBlock);

    // Gestionnaire d'événements de défilement pour réobserver lorsque la page est scrollée
    window.addEventListener('scroll', () => {
      observer.observe(advantagesBlock);
    });
  }
});
